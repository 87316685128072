<template>
<v-container >
  
  <v-card color="basil" class="main mx-auto my-12" elevation="0" width="700px">
    <v-card-title>
           <v-img
         :src="require('../assets/logo.svg')"
        />
    </v-card-title>
   </v-card>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      class="tabs"
      centered
    >
      <v-tab
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

     <v-tabs-items v-model="tab" :style="{background: 'lightgray'}">
      <v-tab-item
        :key="Tjenester"
      >
        <v-card
          color="basil"
          width="600px"
          class="mx-auto my-12"
        >
            <div class="verticalspace1"/>
<div class="textcontainer">
        <v-card-title>Programvareutvikling og systemarkitektur</v-card-title>
          <v-card-text class="flex">
            <div class="verticalspace2"/>


            <p>Frontend-utvikling i VueJS, React og Angular </p>
            <p>Backend-utvikling i .NET</p>
            <p>Skyløsninger i Microsoft Azure og AWS</p>
            <p>Autentisering og sikkerhet</p>

          </v-card-text>
            <div class="verticalspace1"/>
          </div>
        </v-card>
      </v-tab-item>
      
      <v-tab-item ref=""
        :key="Kontakt"
      >
        <v-card
          color="basil"
          width="600px"
          class="mx-auto my-12"

        >
  <div class="textcontainer">        <v-card-text class="flex">
            <div class="verticalspace1"/>

            <p><b>Tinker AS</b></p>
            <p>Frøyerveien 47</p> 
            <p>4328 Sandnes</p>
            <p>org 829 327 562</p>
            <p><a href="mailto:runar@tinker.no">runar@tinker.no</a></p>
            <p>tlf 90512463</p>

           </v-card-text></div>
            <div class="verticalspace1"/>

        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
  <!-- </div> -->
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      tab: null,
      items: [
          'Tjenester', 'Kontakt',
        ],
      
    }),
  }
</script>

<style scoped>
/* Helper classes */
.main{
  overflow: hidden;
  background-color: lightgray !important;

  /* margin-top: 200px; */
}
.basil {
  background-color: lightgray !important;
}
.basil--text {
  color: #356859 !important;
}

.verticalspace1{
  height: 40px;
}
.verticalspace2{
  height: 20px;
}
.textcontainer{
  margin-left: 0px;
  justify-content: center;
}
.flex{
  display: flex;
  flex-direction: column;
}
</style>
