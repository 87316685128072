<template>
  <v-app id="main" :style="{background: $vuetify.theme.themes[theme].background}">
    <!-- <v-app-bar
      app
      color=""
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
         :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="100"
        />

      </div>


    </v-app-bar> -->

    <v-main>
      <MainComponent class="main"/>
    </v-main>
  </v-app>
</template>

<script>
import MainComponent from './components/MainComponent';

export default {
  name: 'App',

  components: {
    MainComponent,
  },
    computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
    },

  data: () => ({
    //
  }),
};
</script>
<style>
body{
  background: lightgray;

}
</style>
<style scoped>

main{
  height: 100vh;
  background-color: lightgray;

}
</style>
